
import { useState } from 'react';
import './App.css'
function App() {
  const [hove,setHove] = useState(false)
  const [hove1,setHove1] = useState(false)
  return (
    <div className="flex justify-center items-center w-full h-screen">
      <div className='bg-white rounded-xl w-5/6  py-20 md:w-3/5  border-4 border-gray-400 flex items-center flex-col'> 
        <img  src='/megaLogo.svg' className='md:w-1/3 w-3/4  pb-10'/>
        <span className='text-[18px] mt-[10px] md:mt-[20px] font-semibold leading-[26px] text-[#404046] font-sans'>Hãy chọn phương thức đăng nhập</span>
        <a  onMouseEnter={()=>{setHove(true)}} onMouseLeave={()=>{setHove(false)}} href='https://online.megaschool.edu.vn/login/openid_connect?hcm=true' className='py-[18px] px-[50px]  text-[14px] md:text-[18px]  font-semibold  mt-[24px] flex space-x-4 justify-start items-center lg:w-[400px] w-5/6  rounded-full bg-[#F2F2F7] hover:bg-[#3555F2] text-[#232326] hover:text-white'>
          {hove?<img  src='/user.svg'/>:<img  src='/user2.svg'/>}
          <span>Đăng nhập bằng Sở GD&ĐT</span>
        </a>
        <a  onMouseEnter={()=>{setHove1(true)}} onMouseLeave={()=>{setHove1(false)}} href='https://online.megaschool.edu.vn/login/canvas?login=admin' className='py-[18px] px-[50px]  text-[14px] md:text-[18px]  font-semibold  mt-[24px] flex space-x-4 justify-start items-center lg:w-[400px] w-5/6  rounded-full bg-[#F2F2F7] hover:bg-[#3555F2] text-[#232326] hover:text-white'>
          {hove1?<img  src='/user.svg'/>:<img  src='/user2.svg'/>}
          <span>Đăng nhập bằng Megaschool</span>
        </a>
        <a href='https://online.megaschool.edu.vn/login/google' className=' py-[18px] px-[50px]  text-[14px] md:text-[18px]  font-semibold  mt-[16px] flex space-x-4 justify-start items-center lg:w-[400px] w-5/6  rounded-full bg-[#F2F2F7] hover:bg-[#3555F2] text-[#232326] hover:text-white'>
          <img  className='pr-[5px]' src='/Google.svg'/>
          <span>Đăng nhập bằng Google</span>
        </a>
        <a href='https://online.megaschool.edu.vn/login/microsoft' className='py-[18px] px-[50px]  text-[14px] md:text-[18px]  font-semibold  mt-[16px] flex space-x-4 justify-start items-center lg:w-[400px] w-5/6  rounded-full bg-[#F2F2F7] hover:bg-[#3555F2] text-[#232326] hover:text-white'>
          <img  className='pr-[5px]' src='/Icon.svg'/> 
          <span>Đăng nhập bằng Microsoft</span>
        </a>
      </div>
      
    </div>
  );
}

export default App;
